import { useEffect, useState } from 'react'

export default function GaugeMMM({
  value,
  max = 100,
  invert = false,
  ...props
}) {
  const colors = invert
    ? ['#84cc16', '#22c55e', '#eab308', '#f59e0b', '#f97316']
    : ['#f97316', '#f59e0b', '#eab308', '#22c55e', '#84cc16']
  let color = colors[0]
  if (value < 20) color = colors[0]
  else if (value < 40) color = colors[1]
  else if (value < 60) color = colors[2]
  else if (value < 80) color = colors[3]
  else color = colors[4]

  const [k, setk] = useState(0)

  useEffect(() => {
    const rrender = () => setk((k) => k + 1)
    window.addEventListener('resize', rrender)
    return () => window.removeEventListener('resize', rrender)
  }, [])

  function ArcSVG({ progress }) {
    const cx = 50,
      cy = 42,
      r = 35,
      strokeWidth = 8
    const startAngle = 180
    const endAngle = startAngle + progress * 180

    function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      const angleInRadians = (angleInDegrees * Math.PI) / 180.0
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      }
    }

    function describeArc(x, y, radius, startAngle, endAngle) {
      const start = polarToCartesian(x, y, radius, endAngle)
      const end = polarToCartesian(x, y, radius, startAngle)
      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'
      return `M ${end.x} ${end.y} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${start.x} ${start.y}`
    }

    const filledArc = describeArc(cx, cy, r, startAngle, endAngle)
    const remainingArc = describeArc(cx, cy, r, endAngle, 360)

    return (
      <svg width="100" height="80" viewBox="0 0 100 80">
        <path
          d={`${remainingArc}`}
          stroke="gray"
          stroke-width={`${strokeWidth}`}
          fill="none"
          strokeLinecap="round"
        />
        <path
          d={`${filledArc}`}
          stroke={color}
          stroke-width={`${strokeWidth}`}
          fill="none"
          strokeLinecap="round"
        />
      </svg>
    )
  }
  return (
    <div>
      <div className="max-h-[48px]">
        <ArcSVG progress={value / 100} />
      </div>
      <div className="text-center absolute min-w-full left-0 mt-[-20px]">
        <span>{value}%</span>
      </div>
    </div>
  )
}
