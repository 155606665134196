import { useEffect, useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useQuery, useQueryClient } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { getGraphDetails, getModel } from '../services/model'

import BreadcrubsModel from './BreadcrumbsModel'
import ViewData from './ViewData'
import Loader from './Loader'
import ModelInsights from './ModelInsights'
import Optimization from './Optimization'
import ModelPlanning from './ModelPlanning'
import { useAuth } from '../providers/AuthProvider'
import { getModelStatus } from '../utility/model'

import AIChat from './AIChat'

const STEP_COMPONENTS = [ViewData, ModelInsights, Optimization, ModelPlanning]

export default function ModelContent({ pushHeader, popHeader, ...props }) {
  const { token } = useAuth()
  const queryClient = useQueryClient()
  const { modelId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const graphLocation = useRef([])
  const activeStep = Number.parseInt(searchParams.get('step')) || 0
  const setActiveStep = (step) => {
    searchParams.set('step', step)
    setSearchParams(searchParams)
  }
  const Component = STEP_COMPONENTS?.[activeStep] ?? STEP_COMPONENTS[0]

  const { data: model } = useQuery(
    ['model', modelId],
    async () => {
      const response = await getModel({ modelId, token })
      if (!response?.ok) throw new Error(response?.statusText)
      const model = await response.json()
      return model
    },
    { staleTime: Infinity },
  )
  window.model = model

  const { data: graphDetails, isLoading: isLoadingsDetails } = useQuery(
    ['graphDetails', modelId],
    async () => {
      if (model?.version !== 'v2') return null
      const response = await getGraphDetails({ modelId, token })
      if (!response?.ok) throw new Error(response?.statusText)
      const graphDetails = await response.json()
      return graphDetails
    },
    { staleTime: Infinity, enabled: !!model },
  )
  useEffect(() => {
    if (
      !graphDetails &&
      !isLoadingsDetails &&
      model?.version === 'v2' &&
      model?.status === 'trained'
    ) {
      const to = setInterval(() => {
        queryClient.invalidateQueries(['graphDetails', modelId])
      }, 3000)
      return () => clearInterval(to)
    }
  }, [graphDetails, isLoadingsDetails, model])

  useEffect(() => {
    pushHeader('MODEL CONFIGURATION')
    return () => popHeader()
  }, [])

  useEffect(() => {
    const status = getModelStatus(model)
    if (status === 'training' || status === 'optimizing') {
      const to = setTimeout(() => {
        queryClient.invalidateQueries(['model', modelId])
      }, 5000)
      return () => clearTimeout(to)
    }
  }, [model])

  const prevState = useRef('')
  useEffect(() => {
    const transition = `${prevState?.current ?? ''}_${model?.status ?? ''}`
    prevState.current = model?.status ?? ''
    queryClient.invalidateQueries(['modelHistory', model?.id])
    switch (transition) {
      case 'training_trained':
        queryClient.invalidateQueries(['model-progress', model?.id])
        queryClient.invalidateQueries(['mmm-optimized-table-spend', model?.id])
        queryClient.invalidateQueries(['mmm-model-statistics', model?.id])
        queryClient.invalidateQueries(['mediaContribution-lines', model?.id])
        queryClient.invalidateQueries(['mmm-funnel-effect', model?.id])
        queryClient.invalidateQueries(['mmm-total-funnel-effect', model?.id])
        queryClient.invalidateQueries(['lag-carryover', model.id])
        queryClient.invalidateQueries(['MMMDynamicSpend', model.id])
        queryClient.invalidateQueries(['MMMInfluence', model.id])
        queryClient.invalidateQueries(['mediaContribution', model.id])
        queryClient.invalidateQueries(['saturation-curves', model.id])
        queryClient.invalidateQueries(['model-optimization-table', model.id])
        queryClient.invalidateQueries([
          'model-budget-optimization-original',
          model.id,
        ])
        queryClient.invalidateQueries(['graphDetails', model?.id])
        break
      case 'optimizing_trained':
      case 'optimizing_created':
      case 'optimizing_error':
        queryClient.invalidateQueries(['budget-optimize', model?.id])
        queryClient.invalidateQueries(['outcome-optimize', model?.id])
        queryClient.invalidateQueries([
          'mmm-model-optimization-outcome',
          model?.id,
        ])
        queryClient.invalidateQueries(['model-optimization-table', model.id])
        queryClient.invalidateQueries()
        queryClient.invalidateQueries()
        break
      default:
        break
    }
  }, [model])

  return (
    <Row>
      {graphDetails && (
        <AIChat
          details={graphDetails}
          model={model}
          graphLocation={graphLocation}
        />
      )}
      <Col className="mb-4" xs={12}>
        <BreadcrubsModel
          activeStep={activeStep}
          onChange={setActiveStep}
          model={model}
        />
      </Col>
      <Col xs={12}>
        {model ? (
          <Component model={model} graphLocation={graphLocation} {...props} />
        ) : (
          <Loader />
        )}
      </Col>
    </Row>
  )
}
