import { useEffect, useMemo, useRef, useState, Fragment } from 'react'
import { BsStars } from 'react-icons/bs'
import mmmLogo from '../assets/logos/MMM_icon_c.svg'
import julia from '../assets/images/julia.png'
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa'
import { Col, Form, Overlay, Popover, Row } from 'react-bootstrap'
import { GrSend } from 'react-icons/gr'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { getQueryStatus, queryModel } from '../services/model'
import { useAuth } from '../providers/AuthProvider'
import { FaUser } from 'react-icons/fa6'
import { IoMdClose } from 'react-icons/io'
import { toast } from 'react-toastify'
import MarkdownView from 'react-showdown'

function Header({ text, size }) {
  return (
    <div
      className="mb-1 mt-4 text-white font-bold"
      style={{ fontSize: `${size}px` }}
    >
      {(text ?? []).map((c, i) => (
        <Fragment key={i}>{`${c}`}</Fragment>
      ))}
    </div>
  )
}

function Message({ type, content, loading, ...props }) {
  const { t } = useTranslation()
  const { user } = useAuth()
  if (Array.isArray(content)) {
    content = content.findLast((c) => c.type === 'text')?.text
  }

  if (type === 'user' && !loading)
    return (
      <div>
        <div
          className={`mt-4 float-end static border-1 rounded-xl p-3 w-fit !bg-[#ffffff12] me-3 ${props?.className ?? ''}`}
        >
          <div className="">
            {user?.picture ? (
              <img
                src={user?.picture}
                alt="user"
                className="inline-block h-[41px] mb-2 border-white border-2 rounded-full"
              />
            ) : (
              <FaUser size={22} className="!ms-[-8px]" />
            )}
            <span className="ms-2 text-white font-bold">{t('Me')}</span>
          </div>
          <div className="ps-1 mt-1 !text-[0.9rem] ai-message-content">{`${content}`}</div>
        </div>
      </div>
    )
  else
    return (
      <div>
        <div
          className={`mt-4 ms-3 float-start border-1 rounded-xl p-3 border-morpheusMain w-fit !bg-[#4240B512] max-w-full ${props?.className ?? ''}`}
        >
          <div className="">
            <img
              src={julia}
              alt="morpheus:"
              className="inline-block h-[45px] mb-2"
            />
            <span className="ms-2 text-white font-bold">
              Julia from Dataslayer
            </span>
          </div>
          <div className="pe-4 mt-1 text-[0.9rem] ai-message-content">
            {loading ? (
              <>
                <span className="loading-tooltip text-gray-300 italic">
                  {t('Typing')}
                </span>
              </>
            ) : (
              <>
                <pre className="text-pretty force-wraps !text-[0.9rem]">
                  <MarkdownView
                    markdown={`${content}`}
                    options={{ tables: true, emoji: true }}
                    className="markdown-content"
                    components={{
                      a({ href, children }) {
                        return (
                          <a href={href} target="_blank" rel="noreferrer">
                            {(children ?? []).map((c, i) => (
                              <Fragment key={i}>{`${c}`}</Fragment>
                            ))}
                          </a>
                        )
                      },
                      h1({ children }) {
                        return <Header text={children} size={18} />
                      },
                      h2({ children }) {
                        return <Header text={children} size={18} />
                      },
                      h3({ children }) {
                        return <Header text={children} size={16} />
                      },
                      h4({ children }) {
                        return <Header text={children} size={16} />
                      },
                      h5({ children }) {
                        return <Header text={children} size={16} />
                      },
                    }}
                  />
                </pre>
              </>
            )}
          </div>
        </div>
      </div>
    )
}

const SUGGESTIONS = [
  "Explain the current graph and it's insights",
  'What is the train-test split?',
  'What are some guidelines I should follow for allocating my budget?',
  'Which channels are underperforming?',
  'Which non media contributions should I look for to maximize KPI?',
  'What are the top 3 most important features?',
  'What is the shape effect graph?',
  'What does accuracy mean within this model?',
  "What is the model's accuracy?",
  'What is the error margin in response model accuracy?',
]
const SUGGESTIONS_PER_PAGE = 2

function Suggestions({ onSelect = () => {} }) {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)

  const suggestions = useMemo(() => {
    return SUGGESTIONS.map((s) => t(s))
  }, [t])

  const totalPages = Math.ceil(suggestions.length / SUGGESTIONS_PER_PAGE)
  const currentSuggestions = suggestions.slice(
    page * SUGGESTIONS_PER_PAGE,
    (page + 1) * SUGGESTIONS_PER_PAGE,
  )

  return (
    <div className="absolute w-full top-[-120px]">
      <Row className="max-w-full mx-2 !min-h-[100px] !max-h-[100px] ">
        <Col className="px-0 flex items-center justify-center" xs={1}>
          {page > 0 && (
            <FaArrowAltCircleLeft
              size={25}
              onClick={() => setPage(page - 1)}
              className="cursor-pointer text-[#9c9c9c] active:text-morpheusMain active:scale-95"
            />
          )}
        </Col>
        {currentSuggestions.map((s, i) => (
          <Col
            xs={5}
            key={i + page * SUGGESTIONS_PER_PAGE}
            className="text-center cursor-pointer flex items-center relative"
          >
            <button
              className="bg-transparent text-[#9c9c9c] border-[#9c9c9c] border-1 p-2 rounded-xl items-center min-h-full flex justify-center text-center text-sm user-select-none font-bold duration-200 active:scale-95"
              onClick={() => onSelect(s)}
            >
              {s}
            </button>
          </Col>
        ))}
        <Col className="px-0 flex items-center justify-center" xs={1}>
          {page < totalPages - 1 && (
            <FaArrowAltCircleRight
              size={25}
              onClick={() => setPage(page + 1)}
              className="cursor-pointer text-[#9c9c9c] active:text-morpheusMain active:scale-95"
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default function AIChat({ model, details, graphLocation }) {
  const { token } = useAuth()
  const { t } = useTranslation()
  const inputRef = useRef()
  const buttonRef = useRef()
  const [showPopup, setShowPopup] = useState(true)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (visible) setShowPopup(false)
  }, [visible])

  const [currentMessage, setCurrentMessage] = useState(null)
  const timestamp = useRef(Date.now())
  const numMessages = useRef(0)
  const lastState = useRef(null)
  const containerRef = useRef()

  const { data } = useQuery(
    ['model', model?.id, timestamp.current],
    async () => {
      if (!model) return null
      const response = await getQueryStatus({
        modelId: model?.id,
        timestamp: timestamp.current,
        token,
      })
      if (response.ok) {
        const result = await response.json()
        if (result?.history?.length > numMessages.current) {
          setCurrentMessage(null)
          numMessages.current = result?.history?.length
        }
        if (result?.status !== lastState.current) {
          try {
            setTimeout(() => {
              const r = containerRef.current
              if (r) r.scrollTop = r.scrollHeight
            }, 500)
          } catch (e) {}
          lastState.current = result?.status
        }
        return result
      } else {
        throw new Error(response.statusText)
      }
    },
    { staleTime: Infinity, retry: true },
  )

  const queryClient = useQueryClient()
  useEffect(() => {
    if (data?.status === 'working' || currentMessage) {
      const to = setInterval(() => {
        queryClient.invalidateQueries(['model', model?.id, timestamp.current])
      }, 2000)
      return () => clearInterval(to)
    }
  }, [currentMessage, data?.status])
  const working = currentMessage || data?.status === 'working'

  useEffect(() => {
    if (!working) inputRef?.current?.focus()
  }, [working])

  if (!model) return null

  const doQuery = () => {
    const value = inputRef.current.value
    setCurrentMessage(value)
    inputRef.current.value = ''
    if (value) {
      queryModel({
        modelId: model.id,
        timestamp: timestamp.current,
        message: value,
        location: Array.isArray(graphLocation.current)
          ? graphLocation.current
          : [],
        token,
      }).then((r) => {
        if (r.ok) {
          setTimeout(() => {
            const r = containerRef.current
            if (r) r.scrollTop = r.scrollHeight
          }, 500)
        } else toast.error('Error sending message')
      })
    }
  }
  return (
    <div
      className={`z-[9991] fixed top-[0px] !min-h-[100vh] !max-h-[100vh] right-[0px] px-0 ${visible ? '!min-w-[30vw] !max-w-[30vw]' : '!min-w-[0vw] !max-w-[0vw]'} duration-300 bg-[#1c242df9] !border-morpheusMain border-l`}
    >
      {details?.suggestion && (
        <Overlay target={buttonRef.current} show={showPopup} placement="left">
          {({ ...props }) => (
            <Popover
              id="popover-basic"
              {...props}
              style={{ ...(props?.style ?? {}), minWidth: '450px' }}
            >
              <Popover.Body>
                <Row className="relative">
                  <button
                    className="absolute top-[-30px] right-[0] !w-[25px] h-[25px] text-[#b5b7c2] border-2 rounded-full border-[#b5b7c2] !p-[2px] flex justify-center items-center"
                    onClick={() => setShowPopup(false)}
                  >
                    <IoMdClose size={25} />
                  </button>
                  <Col className="!max-w-[90px] pe-0 flex items-center" xs={2}>
                    <img src={julia} className="w-[80px] rounded-full" />
                  </Col>
                  <Col
                    className="min-w-[calc(100%-90px)] max-w-[calc(100%-50px)] font-bold text-black"
                    xs={10}
                  >
                    {Number.parseInt(model?.accuracy) || 0}% accuracy! Curious
                    about what’s driving your results?
                  </Col>
                </Row>
              </Popover.Body>
            </Popover>
          )}
        </Overlay>
      )}
      <button
        className={`bg-[#1c242df9]  w-[65px] border-morpheusMain hover:!border-morpheusMain rounded-l-3xl duration-500 flex flex-nowrap items-center !px-0 absolute left-[-65px] active:scale-y-95 mt-[100px] after-button-aichat !min-h-[50px] !max-h-[50px] ${visible ? 'assistant-button-visible' : 'assistant-button-hidden'}`}
        onClick={() => {
          setVisible(!visible)
          if (!visible)
            setTimeout(() => {
              const r = containerRef.current
              if (r) r.scrollTop = r.scrollHeight
            }, 500)
        }}
        ref={buttonRef}
      >
        {visible ? (
          <FaChevronRight className="inline-block ms-1 text-white" size={20} />
        ) : (
          <FaChevronLeft className="inline-block ms-1 text-white" size={20} />
        )}
        <BsStars size={28} className="text-[#7724FF] px-0" />
      </button>
      {visible && (
        <div className="min-h-[100vh] max-h-[100vh] relative flex flex-col flex-nowrap">
          <div
            ref={containerRef}
            className="overflow-y-auto flex flex-col flex-nowrap px-4 text-sm pt-4 pb-[15px]"
            style={{
              height: 'calc(100vh - 65px)',
            }}
          >
            {details?.suggestion && (
              <Message type="system" content={details.suggestion} />
            )}
            {currentMessage || data?.history?.length ? (
              <>
                {data?.history
                  ?.slice(details?.suggestion ? 2 : 1)
                  ?.map((message, index) => (
                    <Message
                      key={index}
                      type={message.role}
                      content={message.content}
                    />
                  ))}
                {currentMessage && (
                  <Message type="user" content={currentMessage} />
                )}
              </>
            ) : (
              <>
                {!details?.suggestion && (
                  <>
                    <div className="flex justify-center mt-[30vh]">
                      <img src={mmmLogo} className="w-[170px]" />
                    </div>
                    <div className="text-center px-5 mt-4 text-sm font-bold">
                      {t(
                        'Welcome to Morpheus assistant, I will answer your questions about the model in real time.',
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {working && <Message type="system" content={''} loading={true} />}
          </div>
          <div className="min-h-[65px] max-h-[65px] relative">
            {!working && !currentMessage && !data?.history?.length && (
              <Suggestions
                onSelect={(s) => {
                  inputRef.current.value = s
                  doQuery()
                }}
              />
            )}
            <div className="px-4 relative">
              <Form.Control
                autoFocus
                className="text-input-mmm py-2 !pe-[50px] !ps-[15px]"
                placeholder={t('Ask me anything')}
                ref={inputRef}
                disabled={working}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    doQuery()
                  }
                }}
              />
              <button
                className="absolute right-[35px] top-[calc(50%-14px)] bg-transparent hover:text-mmmbutton duration-300 !border-none px-[4px] py-[4px] active:scale-95"
                disabled={working}
                onClick={() => {
                  doQuery()
                }}
              >
                <GrSend size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
