import React, { createContext, useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import useStoreState from '../hooks/UseStoreState'
import { config, switchContext } from '../Constants'
import { useQuery, useQueryClient } from 'react-query'
import { getUser, getUserPlanUsage, getVersion } from '../services/model'

const AuthContext = createContext({})

const SWITCH_PLAN = new Set(['pro', 'essential', 'pro trial'])

export function AuthProvider({ children }) {
  const queryClient = useQueryClient()
  const [storedData, setStoredData, forceRewrite] = useStoreState({
    key: 'auth',
  })

  const signout = () => {
    window.doLogout = true
    setStoredData({ key: 'auth', data: {}, ttl: 0 })
  }

  if (!window.sentry) {
    window.sentry = 'Enabled'
    Sentry.init({
      dsn: 'https://07e33dd408f2de812e5d678b363dcbc2@o4505680367190016.ingest.us.sentry.io/4508261808996352',
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            'https://test-mmm.dataslayer.ai',
            'https://app.dataslayer.ai',
          ],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }

  const { data: user } = useQuery(
    ['user-data', storedData?.data?.token],
    async () => {
      if (storedData?.data?.token) {
        const res = await getUser({ token: storedData?.data?.token })
        if (res.ok) return await res.json()
        throw new Error('Error fetching user data')
      }
    },
    { staleTime: 20000 },
  )

  const { data: planUsage } = useQuery(
    ['user-plan-usage', storedData?.data?.token],
    async () => {
      if (!storedData?.data?.token) return null
      const res = await getUserPlanUsage({ token: storedData?.data?.token })
      if (res.ok) return await res.json()
      throw new Error('Error fetching plan usage')
    },
    { staleTime: Infinity },
  )

  const { data: version } = useQuery(
    'version',
    async () => {
      const version = await getVersion()
      if (version.ok) {
        const data = await version.json()
        return data.morpheus_version
      }
      return 'v1'
    },
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (
      version === 'v1' &&
      !SWITCH_PLAN.has(user?.plan?.toLowerCase()) &&
      storedData?.data?.token
    ) {
      switchContext({
        version: 'v2',
        extraParams: { token: storedData?.data?.token },
      })
    }
  }, [storedData?.data?.token, version, user])

  const value = {
    user,
    version,
    planUsage: planUsage?.usage,
    token: storedData?.data?.token,
    showStartTrial:
      user?.plan === 'Trial' ||
      user?.plan === 'Free' ||
      user?.plan === 'Extended Trial' ||
      user?.plan === 'Pro Trial',
    isEssential:
      !user?.plan ||
      user?.plan === 'Essential' ||
      user?.plan === 'Free' ||
      user?.plan === 'Trial',
    refreshUsage: () =>
      queryClient.invalidateQueries([
        'user-plan-usage',
        storedData?.data?.token,
      ]),
    setToken: (token) => {
      const data = {
        ...(storedData?.data ?? {}),
        token: token,
      }
      setStoredData({ ...storedData, data })
    },
    signout,
  }
  window.userAuth = value
  return <AuthContext.Provider value={value}> {children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}
export function RequireAuth({ children }) {
  let auth = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()

  useQuery(
    ['valid-auth', auth?.token, searchParams.get('token')],
    async () => {
      if (searchParams.get('token')) return
      if (auth?.token) {
        const response = await getUser({ token: auth.token })
        if (!response.ok && response.status === 401) {
          auth.signout()
        }
      }
    },
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (searchParams.get('token')) {
      const token = searchParams.get('token')
      auth.setToken(token)
      if (auth.token && auth.token === token) {
        searchParams.delete('token')
        setSearchParams(searchParams)
      }
    } else if (!auth?.token) {
      if (window.doLogout)
        window.location.href = `${config.DATASLAYER_URL}/logout`
      else window.location.href = `${config.DATASLAYER_URL}/login?redirectMMM=1`
    }
  }, [auth])

  return children
}
