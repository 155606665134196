import MarkdownView from 'react-showdown'
import { Col, Row } from 'react-bootstrap'
import { BsStars } from 'react-icons/bs'

export default function AIOverview({ content, ...props }) {
  return (
    <Row className={`mt-4 float-start   py-3 ps-3  ${props?.className}`}>
      <Col xs={12}>
        <button className="p-0 bg-transparent !border-none">
          <h5
            className="border-morpheusMain border-1 w-fit !bg-[#4240B511] p-2 rounded-2xl"
            onClick={() =>
              document.querySelector('.assistant-button-hidden')?.click()
            }
          >
            Assistant Overview{' '}
            <BsStars size={28} className="text-[#7724FF] inline-block" />
          </h5>
        </button>
      </Col>
      <Col className="mt-3 border-s-2 border-morpheusMain" xs={12}>
        <MarkdownView
          markdown={content?.replaceAll('#', '##')}
          options={{ tables: true, emoji: true }}
          className="markdown-content"
        />
      </Col>
    </Row>
  )
}
