import React, { useMemo, useState, useRef, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap'
import { getGraphDetails, getMMMTotalFunnelEffect } from '../../services/model'
import Loader from '../Loader'
import { ResponsiveHeatMapCanvas } from '@nivo/heatmap'
import { defaultFormat, round } from '../../utility/format'
import { BasicTooltip } from '@nivo/tooltip'
import {
  generateMMMColorMap,
  getMMMDataColumnInfo,
  nivoProps,
} from '../../utility/model'
import { useAuth } from '../../providers/AuthProvider'
import { useTranslation } from 'react-i18next'
import { placeholderKPISummaryBreakdown } from './placeholders'
import UpgradeToPro from './UpgradeToPro'
import CustomSelect from '../CustomSelect'
import { ResponsiveBar } from '@nivo/bar'
import { FcDeleteColumn, FcTodoList } from 'react-icons/fc'
import { MdInvertColors } from 'react-icons/md'
import MarkdownView from 'react-showdown'
import AIOverview from '../AIOverview'

const MARGIN_TOP = 0
const MARGIN_RIGHT = 120
const MARGIN_BOTTOM = 80
const MARGIN_LEFT = 50

const monthFormat = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')

  return `${year}-${month}`
}

const HEATMAP_MARKDOWN = `
  This graph describes the contribution and effects of each variable in the model broken down by date.

  - **X-axis**: Date
  - **Y-axis**: Contribution
  - **Color**: Contribution (Red: Negative, Blue: Positive)
`

export default function TotalFunnelEffect({
  model,
  inView = true,
  isInView,
  onChangeVariable = () => {},
  ...props
}) {
  const { t } = useTranslation()
  const { token, isEssential } = useAuth()
  const requirePro = isEssential && !model?.read_only
  const ref = useRef()
  const [graphHeight, setGraphHeight] = useState(250)
  const [mode, setMode] = useState(false)
  const itemRef = useRef()

  const { isLoading: loadingFunnel, data } = useQuery(
    ['mmm-total-funnel-effect', model?.id],
    async () => {
      if (requirePro) return placeholderKPISummaryBreakdown
      if (model?.id) {
        const response = await getMMMTotalFunnelEffect({
          modelId: model.id,
          token,
        })
        if (response.ok) return await response.json()
      }
    },
    { staleTime: Infinity },
  )

  const [heatmaps, isDate, columnInfo, bounds] = useMemo(() => {
    if (data && data?.columns && data?.data && data?.index) {
      const columnInfo = getMMMDataColumnInfo(
        model,
        (mode ? t('month') : t('week')) + ' ',
        { fitMonday: true },
      )
      const colorMap = generateMMMColorMap(model)
      const dateCol = model?.training_config?.datetime_col
      const isDate = model?.column_types?.[dateCol] === 'Datetime'

      let min = Infinity
      let max = -Infinity

      const res = data.index.map((v, i) => {
        return {
          id: v,
          color: colorMap[v] ?? '#4240B5',
          data: data.data[i].map((val, j) => {
            min = Math.min(min, val)
            max = Math.max(max, val)
            return {
              x: j,
              y: Math.abs(val) < 0.01 ? 0 : val,
            }
          }),
        }
      })
      const bound = Math.max(Math.abs(min), Math.abs(max))

      res.forEach((d) =>
        d.data.forEach((c) => {
          if (!c.y) {
            c.y = 0
            c.o = 0
            return
          }
          const [n, base] = c.y < 0 ? [-1, Math.abs(min)] : [1, Math.abs(max)]
          let l = 0.05 * (1 - Math.log(Math.abs(c.y)) / Math.log(base))
          l = n * l * bound + c.y
          c.o = c.y
          c.y = l
        }),
      )

      if (mode) {
        const res2 = []
        res.forEach((d, i) => {
          const agg = {}
          d.data.forEach((c, j) => {
            const mapping = columnInfo.mapMonth(c.x)
            Object.keys(mapping).forEach((k) => {
              agg[k] = agg[k] ?? {
                y: 0,
                o: 0,
              }
              agg[k].y += c.y * mapping[k]
              agg[k].o += c.o * mapping[k]
            })
          })
          const seriesData = Object.entries(agg).map(([x, data]) => ({
            x,
            ...data,
          }))
          res2.push({
            ...d,
            data: seriesData,
            color: colorMap[d.id] ?? '#4240B5',
          })
        })
        min = Infinity
        max = -Infinity
        res2.forEach((d) => {
          d.data.forEach(({ y }) => {
            min = Math.min(min, y)
            max = Math.max(max, y)
          })
        })
        const bound2 = Math.max(Math.abs(min), Math.abs(max))
        return [res2, isDate, columnInfo, bound2 * 0.5]
      }

      return [res, isDate, columnInfo, bound * 0.5]
    }
    return []
    // eslint-disable-next-line
  }, [data, mode])

  useEffect(() => {
    if (ref.current) {
      setGraphHeight(
        Math.max(ref.current.getBoundingClientRect().height - 90, 250),
      )
    }
  }, [ref, heatmaps])
  const [selectedVariable, setSelectedVariable] = useState({
    value: null,
    label: t('All variables'),
  })
  useEffect(() => {
    onChangeVariable(selectedVariable?.value)
  }, [selectedVariable])

  const [canvasWidth, setCanvasWidth] = useState(
    window?.innerWidth - 742 ?? 1000,
  )
  useEffect(() => {
    const update = () => {
      if (itemRef.current) {
        const width =
          itemRef.current.getBoundingClientRect().width -
          (selectedVariable?.value ? 50 : 190 + 120 + 250)
        setCanvasWidth(width)
      }
    }
    window.addEventListener('resize', update)
    update()
    return () => window.removeEventListener('resize', update)
  }, [itemRef.current, selectedVariable])

  const items = heatmaps?.[0]?.data?.length ?? 10
  let buckets = canvasWidth / 20
  buckets = Number.isNaN(buckets) ? items : buckets
  const labelsEvery = Math.max(1, Math.ceil(items / buckets))
  const labelFactor = 1
  const labelFormat = mode ? monthFormat : null

  const csvData = useMemo(() => {
    if (heatmaps) {
      const dates = heatmaps[0].data
        .map((d) => d.x)
        .map((v) =>
          isDate
            ? columnInfo.map(
                Number.parseInt(v) * labelFactor,
                labelFormat,
                mode,
              )
            : `${mode ? t('month') : t('week')} ${Number.parseInt(v) + 1}`,
        )
      const values = heatmaps.map((d) => d.data.map((v) => v.o))
      const result = [
        ['', ...dates],
        ...values.map((v, i) => [heatmaps[i].id, ...v]),
      ]

      return encodeURIComponent(JSON.stringify(result))
    }
    return null
  }, [heatmaps])

  const options = useMemo(() => {
    const result = heatmaps?.map((i) => ({ value: i.id, label: i.id })) ?? []
    result.unshift({ value: null, label: t('All variables') })
    return result
  }, [heatmaps])

  const [filters, setFilters] = useState({})

  const { data: graphDetails } = useQuery(
    ['graphDetails', model?.id],
    async () => {
      const response = await getGraphDetails({ modelId: model.id, token })
      if (!response?.ok) throw new Error(response?.statusText)
      const graphDetails = await response.json()
      return graphDetails
    },
    { staleTime: Infinity },
  )
  const detail =
    graphDetails?.[`kpi_summary_breakdown_${selectedVariable?.value}`]

  if (!loadingFunnel && !heatmaps) return null

  const labels = new Set(
    heatmaps?.[0]?.data?.map((d, i, arr) =>
      !(i % labelsEvery)
        ? columnInfo.map(d.x * labelFactor, labelFormat, mode)
        : '',
    ) ?? [],
  )

  return !heatmaps ? (
    <Loader />
  ) : (
    <Row className={`relative ${requirePro ? 'blur-under' : ''}`} ref={itemRef}>
      {requirePro && <UpgradeToPro />}
      <Col className="flex justify-start no-print mt-2 mb-2 z-50" xs={12}>
        <div
          className="d-inline-flex justify-content-between w-100"
          style={{
            marginTop: `${MARGIN_TOP}px`,
          }}
        >
          <span className="inline-block min-w-[250px]">
            <CustomSelect
              value={selectedVariable}
              type={'dark'}
              className="basic-single  ms-5"
              classNamePrefix="select"
              isClearable={false}
              onChange={(v) => setSelectedVariable(v)}
              options={options}
            />
          </span>

          <Form.Check
            type="switch"
            label={t('Show monthly')}
            className="form-switch-share ps-0 me-5"
            checked={mode}
            onChange={(e) => setMode(e.target.checked ? true : false)}
          />
        </div>
      </Col>
      <div
        className="data-holder display-none"
        data-csv={csvData}
        data-filename={`Cross_channel_impact__${model.id}`}
      ></div>
      <>
        {selectedVariable?.value ? (
          <>
            <Col
              {...props}
              className={`${props?.className ?? ''} px-0 `}
              style={{
                ...(props?.style ?? {}),
                minHeight: `${Math.max(heatmaps?.length * 20 + 50, 250)}px`,
              }}
              xs={12}
            >
              <ResponsiveBar
                {...nivoProps}
                data={heatmaps
                  ?.find((d) => d.id === selectedVariable.value)
                  ?.data.map((d) => ({
                    Date: columnInfo.map(
                      Number.parseInt(d.x),
                      labelFormat,
                      mode,
                    ),
                    Contribution: d.y,
                  }))}
                colors={(d) => '#74b1d3'}
                indexBy="Date"
                keys={['Contribution']}
                enableGridY={false}
                enableGridX={false}
                margin={{ top: 40, right: 30, bottom: 90, left: 100 }}
                padding={0.2}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                label={(_) => <></>}
                axisBottom={{
                  orient: 'bottom',
                  tickPadding: 5,
                  legendOffset: 70,
                  legendPosition: 'middle',
                  tickSize: 2,
                  tickRotation: -45,
                  format: (d, i) => (labels.has(d) ? d : ''),
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendOffset: -80,
                  legendPosition: 'middle',
                  legend: `Contribution`,
                }}
                valueFormat=">-.2f"
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor="black"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
              />
            </Col>
            {detail && (
              <Col xs={12} className="mt-2 !text-xs ms-[20px]">
                <AIOverview content={detail} />
              </Col>
            )}
          </>
        ) : (
          <>
            <Col
              className="pe-0 z-10"
              xs={3}
              style={{
                maxWidth: '190px',
                marginTop: `${0}px`,
                marginBottom: `${MARGIN_BOTTOM}px`,
                marginRight: '0px',
                marginRight: '-50px',
              }}
            >
              <Row className="w-100" style={{ minHeight: '100%' }}>
                {heatmaps
                  ?.filter((d) => !filters?.[d.id])
                  ?.map((d, i) => (
                    <Col
                      className="d-flex align-items-center justify-content-end pe-0 legend-funnel"
                      key={d.id}
                      xs={12}
                      title={d.id}
                    >
                      <span className="d-inline-block text-truncate">
                        {d.id}
                      </span>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col
              {...props}
              className={`${props?.className ?? ''} px-0 `}
              style={{
                maxWidth: 'calc(100% - 390px)',
                ...(props?.style ?? {}),
                minHeight: `${heatmaps?.length * 20 + 50}px`,
              }}
              xs={12}
              ref={ref}
            >
              <ResponsiveHeatMapCanvas
                data={heatmaps.filter((d) => !filters[d?.id])}
                margin={{
                  top: MARGIN_TOP,
                  right: MARGIN_RIGHT,
                  bottom: MARGIN_BOTTOM,
                  left: MARGIN_LEFT,
                }}
                valueFormat=">-.2f"
                label={({ data }) => {
                  return data.y ? `${round(data.y, 2)}%` : ''
                }}
                labelTextColor="#000"
                enableLabels={false}
                axisTop={null}
                axisRight={null}
                axisLeft={null}
                axisBottom={{
                  orient: 'bottom',
                  tickPadding: 5,
                  legendOffset: 60,
                  legendPosition: 'middle',
                  tickSize: 2,
                  tickRotation: -45,
                  format: (d) => {
                    return !(d % labelsEvery) ||
                      d === heatmaps?.[0]?.data?.length - 1
                      ? columnInfo.map(d * labelFactor, labelFormat, mode)
                      : ''
                  },
                }}
                emptyColor="#ffffff"
                colors={{
                  type: 'diverging',
                  scheme: 'red_blue',
                  divergeAt: 0.5,
                  minValue: -bounds,
                  maxValue: bounds,
                }}
                theme={{
                  fontSize: 22,
                  textColor: '#ADBAC7',
                  legends: {
                    ticks: {
                      text: {
                        fontSize: 13,
                        fill: '#ADBAC7',
                      },
                    },
                  },
                  axis: {
                    ticks: {
                      text: {
                        fontSize: 12,
                        fill: '#ADBAC7',
                      },
                    },
                  },
                  tooltip: {
                    container: {
                      fontSize: 11,
                      color: 'black',
                    },
                  },
                }}
                tooltip={({ cell }) => {
                  return (
                    <BasicTooltip
                      id={
                        isDate
                          ? columnInfo.map(
                              cell.data.x * labelFactor,
                              labelFormat,
                              mode,
                            )
                          : `${mode ? t('month') : t('week')} ${Number.parseInt(cell.data.x) + 1}`
                      }
                      color={cell.color}
                      value={defaultFormat({
                        num: cell.data.o === null ? 0 : cell.data.o,
                      })}
                      enableChip
                    />
                  )
                }}
                legends={[
                  {
                    anchor: 'top-right',
                    translateX: 45,
                    translateY: 0,
                    length: graphHeight,
                    thickness: 10,
                    direction: 'column',
                    tickPosition: 'after',
                    tickSize: 5,
                    tickSpacing: 4,
                    tickOverlap: false,
                    tickFormat: '>-.2s',
                    title: `${model?.training_config?.target} →`,
                    titleAlign: 'middle',
                    titleOffset: 4,
                  },
                ]}
                borderWidth={3}
                borderColor={{ from: 'color', modifiers: [] }}
              />
            </Col>
            <Col
              className="px-0 z-10"
              xs={3}
              style={{
                maxWidth: '230px',
                marginTop: `${0}px`,
                marginRight: '0px',
                marginRight: '0px',
              }}
            >
              <Row
                className="ms-2 !px-0 !pt-0 container-line-legend-interactive"
                style={{
                  maxHeight: `${graphHeight}px`,
                  overflowY: 'auto',
                }}
              >
                {
                  <Col className="controls-legend py-2 no-print" xs={12}>
                    <Row className="flex-col">
                      <Col
                        className="px-0 d-flex align-items-center justify-content-center"
                        xs={12}
                      >
                        <ButtonGroup aria-label="Legend controls">
                          <Button
                            className="py-1 original"
                            variant={'secondary'}
                            onClick={() => {
                              setFilters({})
                            }}
                          >
                            <strong>
                              <span className="small d-inline-flex align-items-center">
                                All <FcTodoList />
                              </span>
                            </strong>
                          </Button>
                          <Button
                            className="py-1 original"
                            variant={'secondary'}
                            onClick={() => {
                              setFilters(
                                heatmaps.reduce((a, d) => {
                                  a[d.id] = true
                                  return a
                                }, {}),
                              )
                            }}
                          >
                            <strong>
                              <span className="small d-inline-flex align-items-center">
                                {t('Hide')} <FcDeleteColumn />
                              </span>
                            </strong>
                          </Button>
                          <Button
                            className="py-1 original"
                            variant={'secondary'}
                            onClick={() => {
                              setFilters(
                                heatmaps.reduce(
                                  (a, d) => {
                                    a[d.id] = !a[d.id]
                                    return a
                                  },
                                  { ...filters },
                                ),
                              )
                            }}
                          >
                            <strong>
                              <span className="small d-inline-flex align-items-center">
                                {t('Invert')}
                                <MdInvertColors />
                              </span>
                            </strong>
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                }
                {heatmaps?.map((s, i) => (
                  <Col className={i ? '' : 'mt-2'} key={s.id} xs={12}>
                    <Row
                      className={`interactive-legend-block px-1 me-2 ${
                        filters[s.id] ? 'interactive-legend-block-disabled' : ''
                      }`}
                      onClick={() =>
                        setFilters((f) => ({ ...f, [s.id]: !f[s.id] }))
                      }
                    >
                      <Col
                        className="px-0 d-flex align-items-center"
                        style={{
                          position: 'relative',
                          maxWidth: '100%',
                          minWidth: '100%',
                        }}
                        xs={10}
                      >
                        <span
                          title={s.id}
                          style={{
                            wordBreak: 'break-word',
                            fontSize: '11px',
                          }}
                          className="w-100 detail-legend-interactive whitespace-nowrap max-w-full items-center flex"
                        >
                          <div
                            style={{
                              //Embeed styles, html to canvas fails to parse the styles if they come from classes :(
                              backgroundColor: `${s.color}`,
                              minWidth: '10px',
                              minHeight: '10px',
                              maxWidth: '10px',
                              maxHeight: '10px',
                              borderRadius: '50%',
                              display: 'inline-block',
                              marginRight: '2px',
                            }}
                            className="detail-legend-interative-shape"
                          ></div>
                          <span className="inline-block text-truncate max-w-[calc(100%-10px)]">
                            {s.id}
                          </span>
                        </span>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={12}>
              <AIOverview content={HEATMAP_MARKDOWN} />
            </Col>
          </>
        )}
      </>
    </Row>
  )
}
