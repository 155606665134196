import { useEffect, useMemo, useState } from 'react'
import { Container } from 'react-bootstrap'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { HeroUIProvider } from '@heroui/react'

import SideBar from './components/Sidebar'
import Header from './components/Header'
import Content from './components/Content'
import Footer from './components/Footer'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { AuthProvider, RequireAuth } from './providers/AuthProvider'
import { availableLanguages } from './i18n'

const queryClient = new QueryClient()
window.queryClient = queryClient

function App() {
  useEffect(() => {
    const iv = setInterval(() => {
      clearInterval(iv)
      if (window.CookieConsent) {
        CookieConsent.run({
          cookie: {
            name: 'cc_cookie',
            domain: '.dataslayer.ai',
            path: '/',
            expiresAfterDays: 182,
            sameSite: 'Lax',
            useLocalStorage: false,
          },

          guiOptions: {
            consentModal: {
              layout: 'cloud inline',
              position: 'bottom center',
              equalWeightButtons: true,
              flipButtons: false,
            },
            preferencesModal: {
              layout: 'box',
              equalWeightButtons: true,
              flipButtons: false,
            },
          },

          categories: {
            necessary: {
              enabled: true, // this category is enabled by default
              readOnly: true, // this category cannot be disabled
            },
            analytics: {
              autoClear: {
                cookies: [
                  {
                    name: /^_ga/, // regex: match all cookies starting with '_ga'
                  },
                  {
                    name: '_gid', // string: exact cookie name
                  },
                  {
                    name: '_gcl_au',
                  },
                  {
                    name: '_fbp',
                  },
                  {
                    name: /^utm/,
                  },
                ],
              },
            },
          },

          language: {
            default: 'en',
            translations: {
              en: {
                consentModal: {
                  title: 'We use cookies',
                  description:
                    'Dataslayer uses cookies to analyze information about web page traffic and adapt the content to each visitor. By clicking "Accept All", you consent to the use of ALL the cookies. However, you may visit "Manage Individual preferences" to provide a controlled consent.',
                  acceptAllBtn: 'Accept all',
                  acceptNecessaryBtn: 'Reject all',
                  showPreferencesBtn: 'Manage Individual preferences',
                },
                preferencesModal: {
                  title: 'Manage cookie preferences',
                  acceptAllBtn: 'Accept all',
                  acceptNecessaryBtn: 'Reject all',
                  savePreferencesBtn: 'Accept current selection',
                  closeIconLabel: 'Close modal',
                  sections: [
                    {
                      title: 'Your Privacy Choices',
                      description: `In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.`,
                    },
                    {
                      title: 'Strictly Necessary',
                      description:
                        'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                      //this field will generate a toggle linked to the 'necessary' category
                      linkedCategory: 'necessary',
                    },
                    {
                      title: 'Performance and Analytics',
                      description:
                        'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                      linkedCategory: 'analytics',
                    },
                  ],
                },
              },
            },
          },
        })
      }
    }, 2000)
  }, [])

  const [title, setTitle] = useState(['Morpheus | Dataslayer'])
  const { t, i18n } = useTranslation()
  const [header, setHeader] = useState(['MORPHEUS'])
  useEffect(() => {
    window.document.title = title[title.length - 1]
  }, [title])

  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng')
    if (!availableLanguages.includes(savedLanguage)) i18n.changeLanguage('en')
  }, [])

  const controls = useMemo(() => {
    return {
      pushTitle: (newTitle) => {
        setTitle([...title, newTitle])
      },
      popTitle: () => {
        setTitle((title) =>
          title.length > 1 ? title.slice(0, title.length - 1) : title,
        )
      },
      pushHeader: (newHeader) => {
        setHeader([...header, newHeader])
      },
      popHeader: () => {
        setHeader((header) =>
          header.length > 1 ? header.slice(0, header.length - 1) : header,
        )
      },
    }
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <HeroUIProvider>
            <AuthProvider>
              <RequireAuth>
                <Container id="body-container" className="h-screen p-0" fluid>
                  <SideBar />
                  <Header header={t(header[header.length - 1])} />
                  <Content {...controls} />
                  <Footer />
                </Container>
              </RequireAuth>
            </AuthProvider>
          </HeroUIProvider>
        </Router>
      </QueryClientProvider>
    </LocalizationProvider>
  )
}

export default App
