import { fetchAPI } from './base'

export function getModels({ token }) {
  return fetchAPI({
    endpoint: `models`,
    token,
  })
}

export function getModel({ modelId, token }) {
  return fetchAPI({
    endpoint: `model/${encodeURIComponent(modelId)}`,
    token,
  })
}

export function getColumnSummary({ modelId, token }) {
  return fetchAPI({
    endpoint: `column-summary/${encodeURIComponent(modelId)}`,
    token,
  })
}

export function getHead({ modelId, pageSize = 100, pageNumber = 0, token }) {
  const params = `${
    pageSize || pageSize === 0 ? `&page_size=${pageSize}` : ''
  }${pageNumber || pageNumber === 0 ? `&page_number=${pageNumber}` : ''}`
  return fetchAPI({
    endpoint: `get_head/${encodeURIComponent(modelId)}?${params}`,
    token,
  })
}

export function uploadModel({ model, modelName, token }) {
  const url = `upload?name=${encodeURIComponent(modelName)}`
  return fetchAPI({
    endpoint: url,
    token,
    method: 'POST',
    body: model,
  })
}

export function createEmptyModel({ name, token }) {
  return fetchAPI({
    endpoint: `empty_model?name=${encodeURIComponent(name)}`,
    token,
    method: 'POST',
  })
}

export function updateModel({ modelId, modelName, token }) {
  return fetchAPI({
    endpoint: `model/${modelId}`,
    token,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name: modelName }),
  })
}

export function deleteModel({ modelId, token }) {
  return fetchAPI({
    endpoint: `model/${modelId}`,
    token,
    method: 'DELETE',
  })
}

export function updateModelStatus({ modelId, status, token }) {
  return fetchAPI({
    endpoint: `model-update-status/${modelId}`,
    token,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status }),
  })
}

export function trainModel({ modelId, trainConfig, token }) {
  return fetchAPI({
    endpoint: `train/${modelId}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(trainConfig),
    method: 'POST',
  })
}

export function getModelTrainProgress({ modelId, token }) {
  return fetchAPI({
    endpoint: `train_progress/${encodeURIComponent(modelId)}`,
    token,
  })
}

export function getModelOptimizeProgress({ modelId, token }) {
  return fetchAPI({
    endpoint: `optimize_progress/${encodeURIComponent(modelId)}`,
    token,
  })
}

export function getDataslayerImportReport({ modelId, token }) {
  return fetchAPI({
    endpoint: `dataslayer_import_progress/${encodeURIComponent(modelId)}`,
    token,
  })
}

export function getMMMOptimizedTable({
  modelId,
  is_outcome_optimization,
  weekly,
  original,
  token,
}) {
  const params = `?is_outcome_optimization=${is_outcome_optimization}&weekly=${weekly}&original=${original}`
  return fetchAPI({
    endpoint: `model-optimized-table/${encodeURIComponent(modelId)}${params}`,
    token,
  })
}

export function getMMMStatistics({ modelId, token }) {
  return fetchAPI({
    endpoint: `model-statistics/${encodeURIComponent(modelId)}`,
    token,
  })
}

export async function getMMMModelStackedPlot({ modelId, token }) {
  return fetchAPI({
    endpoint: `model-stacked-plot/${modelId}`,
    token,
  })
}

export async function getMMMFunnelEffect({ modelId, token }) {
  return fetchAPI({
    endpoint: `model-funnel-effect/${modelId}`,
    token,
  })
}

export async function getMMMTotalFunnelEffect({ modelId, token }) {
  return fetchAPI({
    endpoint: `model-total-funnel-effect/${modelId}`,
    token,
  })
}

export async function getMMMLagCarryover({ modelId, token }) {
  return fetchAPI({
    endpoint: `carryover-and-lag/${modelId}`,
    token,
  })
}

export async function getMMMDynamicSpend({ modelId, token }) {
  return fetchAPI({
    endpoint: `dynamic-spend/${modelId}`,
    token,
  })
}

export async function getMMMInfluence({ modelId, token }) {
  return fetchAPI({
    endpoint: `influence/${modelId}`,
    token,
  })
}

export async function getMMMSaturationCurves({ modelId, token }) {
  return fetchAPI({
    endpoint: `saturation-curves/${modelId}`,
    token,
  })
}

export async function getModeltable({ modelId, token }) {
  return fetchAPI({
    endpoint: `model-table/${modelId}`,
    token,
  })
}

export async function getMMMOutcomeBudgetOptimization({
  modelId,
  original = false,
  token,
}) {
  return fetchAPI({
    endpoint: `outcome-budget-optimization/${modelId}?original=${original}`,
    token,
  })
}

export async function optimizeMMM({
  modelId,
  channelToMinSpend,
  channelToMaxSpend,
  outcomeTarget,
  budgetTarget,
  weeks,
  startingDate,
  token,
}) {
  return fetchAPI({
    endpoint: `optimize/${modelId}`,
    method: 'POST',
    body: JSON.stringify({
      channel_to_min_spend: channelToMinSpend,
      channel_to_max_spend: channelToMaxSpend,
      outcome_target: outcomeTarget,
      budget_target: budgetTarget,
      starting_date: startingDate,
      weeks: weeks,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    token,
  })
}

export async function downloadModelFile({ modelId, token }) {
  return fetchAPI({
    endpoint: `download-file/${modelId}`,
    token,
  })
}

export async function generateMMMPresentation({ modelId, token }) {
  return fetchAPI({
    endpoint: `generate-mmm-presentation/${modelId}`,
    method: 'GET',
    token,
  })
}

export async function getMMMPresentation({ modelId, token }) {
  return fetchAPI({
    endpoint: `get-mmm-presentation/${modelId}`,
    method: 'GET',
    token,
  })
}

export async function getUser({ token }) {
  return fetchAPI({
    endpoint: `user`,
    method: 'GET',
    token,
  })
}

export async function getUserPlanUsage({ token }) {
  return fetchAPI({
    endpoint: `plan-usage`,
    method: 'GET',
    token,
  })
}

export async function updateColumnDateFormat({
  modelId,
  column,
  format,
  token,
}) {
  return fetchAPI({
    endpoint: `update-date-format/${modelId}`,
    method: 'POST',
    body: JSON.stringify({
      column,
      format,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    token,
  })
}

export async function importDataslayer({
  modelId,
  essential = false,
  config,
  modelName,
  token,
}) {
  return fetchAPI({
    endpoint: `import-dataslayer?model_name=${modelName}&model_id=${modelId}&essential_import=${essential}`,
    method: 'POST',
    body: JSON.stringify(config),
    headers: {
      'Content-Type': 'application/json',
    },
    token,
  })
}

export async function previewFiles({ files, token }) {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })
  return fetchAPI({
    endpoint: `preview-files`,
    method: 'POST',
    body: formData,
    token,
  })
}

export async function tagFiles({ modelId, files, token }) {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })
  return fetchAPI({
    endpoint: `create-tags/${modelId}`,
    method: 'POST',
    body: formData,
    token,
  })
}

export async function generatePreview({ modelId, config, token }) {
  return fetchAPI({
    endpoint: `generate-file-merge-preview/${modelId}`,
    method: 'POST',
    body: JSON.stringify(config),
    headers: {
      'Content-Type': 'application/json',
    },
    token,
  })
}

export function getHeadPreview({
  modelId,
  pageSize = 100,
  pageNumber = 0,
  token,
}) {
  const params = `${
    pageSize || pageSize === 0 ? `&page_size=${pageSize}` : ''
  }${pageNumber || pageNumber === 0 ? `&page_number=${pageNumber}` : ''}`
  return fetchAPI({
    endpoint: `get-preview-head/${encodeURIComponent(modelId)}?${params}`,
    token,
  })
}

export function getModelLocalFiles({ modelId, token }) {
  return fetchAPI({
    endpoint: `model-files/${modelId}`,
    method: 'GET',
    token,
  })
}

export function deleteModelLocalFile({ modelId, file, token }) {
  return fetchAPI({
    endpoint: `model-files/${modelId}/${encodeURIComponent(file)}`,
    method: 'DELETE',
    token,
  })
}

export function uploadModelLocalFiles({ modelId, files, token }) {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })
  return fetchAPI({
    endpoint: `model-files/${modelId}`,
    method: 'POST',
    body: formData,
    token,
  })
}

export function tagDataslayerImportToLocalFile({
  modelId,
  replaceTag,
  config,
  token,
}) {
  return fetchAPI({
    endpoint: `model-files-tag-dataslayer/${modelId}${replaceTag ? `?replace_tag=${replaceTag}` : ''}`,
    method: 'POST',
    body: JSON.stringify(config),
    headers: {
      'Content-Type': 'application/json',
    },
    token,
  })
}

export function getModelImportConfig({ modelId, token }) {
  return fetchAPI({
    endpoint: `model-dataslayer-import-config/${modelId}`,
    method: 'GET',
    token,
  })
}

export function checkLocation({ location, country_code = false, token }) {
  return fetchAPI({
    endpoint: `check-location?location=${encodeURIComponent(location)}&country=${country_code}`,
    method: 'GET',
    token,
  })
}

export function storeFailedBlend({ data, token }) {
  return fetchAPI({
    endpoint: `report-blend`,
    method: 'POST',
    body: JSON.stringify({ data }),
    headers: {
      'Content-Type': 'application/json',
    },
    token,
  })
}

export function getModelHistory({ modelId, token }) {
  return fetchAPI({
    endpoint: `training-history/${modelId}`,
    method: 'GET',
    token,
  })
}

export function getVersion() {
  return fetchAPI({
    endpoint: ``,
    method: 'GET',
  })
}

export function generateReport({ modelId, token }) {
  return fetchAPI({
    endpoint: `generate-report/${modelId}`,
    method: 'POST',
    token,
  })
}

export function queryModel({
  modelId,
  timestamp,
  message,
  location = [],
  token,
}) {
  return fetchAPI({
    endpoint: `query-model/${modelId}`,
    method: 'POST',
    body: JSON.stringify({
      message,
      location,
      timestamp,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    token,
  })
}

export function getQueryStatus({ modelId, timestamp, token }) {
  return fetchAPI({
    endpoint: `query-model/${modelId}/${timestamp}`,
    method: 'GET',
    token,
  })
}

export function getGraphDetails({ modelId, token }) {
  return fetchAPI({
    endpoint: `graph-details/${modelId}`,
    method: 'GET',
    token,
  })
}

export function getPDFReport({ modelId, token }) {
  return fetchAPI({
    endpoint: `graph-details/${modelId}`,
    method: 'GET',
    token,
  })
}
