import React, { useState, useEffect } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import {
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import { useQuery } from 'react-query'
import { formatDateIndex } from '../utility/format'
import { useMutation } from 'react-query'
import ContentNavbar from './ContentNavbar'
import { Link, useNavigate } from 'react-router-dom'
import { deleteModel, getModel, getModels } from '../services/model'
import ButtonMMM from './ButtonMMM'
import { FaPlus, FaEye, FaSpinner, FaCrown, FaTableList } from 'react-icons/fa6'
import '../styles/models.css'
import { showConfirmToast } from '../utility/toastNotifications'
import { toast } from 'react-toastify'
import ModalUpload from './Modal'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../providers/AuthProvider'
import { FaEdit, FaFilter, FaQuestionCircle, FaTrashAlt } from 'react-icons/fa'
import ModelFilter from './ModelFilter'
import { HELP_URL } from '../Constants'
import ImportModel from './import-model/ImportModel'
import ModelHistory from './ModelHistory'
import { getModelStatus } from '../utility/model'

const getCSSVariableValue = (variableName) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variableName)
    .trim()
}

createTheme(
  'mmm-table-theme',
  {
    text: {
      primary: getCSSVariableValue('--mmm-white-color'),
      secondary: '#2aa198',
    },
    background: {
      default: 'transparent',
    },
    context: {
      background: 'transparent',
      text: '#FFFFFF',
    },
    divider: {
      default: '#323030',
    },
    sortFocus: {
      default: '#2aa198',
    },
    header: {
      style: {
        backgroundColor: getCSSVariableValue('--table-header'),
        color: getCSSVariableValue('--mmm-white-color'),
      },
    },
  },
  'dark',
)

function StartImportButton({ setImportingDataslayer, ...props }) {
  const { t } = useTranslation()
  const { user, isEssential, planUsage } = useAuth()

  try {
    const maxGA4Accounts =
      user?.plan_data?.morpheus_limits?.max_analytics_v4_accounts ?? 0
    const usedGA4Accounts = planUsage?.analytics_v4?.length ?? 0
    if (isEssential && usedGA4Accounts >= maxGA4Accounts) {
      return (
        <OverlayTrigger
          rootClose={true}
          trigger={['hover', 'focus']}
          placement="top"
          delay={{ show: 100, hide: 100 }}
          overlay={({ ...props }) => (
            <Popover {...props} style={{ ...props.style, minWidth: '200px' }}>
              <div
                className="p-2"
                style={{
                  width: '200px',
                  backgroundColor: 'var(--mmm-background)',
                  borderRadius: 5,
                }}
              >
                <div className="text-center py-1">
                  {t(
                    'You have reached the number of imports for this month. Contact us if you have any questions or if you need more.',
                  )}
                </div>
              </div>
            </Popover>
          )}
        >
          <Col xs="auto" className="p-1">
            <ButtonMMM
              className="py-1"
              type="bg-switch"
              onClick={() => setImportingDataslayer(true)}
              disabled
            >
              <span
                {...props}
                className={`inline-flex items-center align-middle ${props?.className ?? ''}`}
              >
                <FaCrown className="mr-2" /> {t('IMPORT DATA')}
              </span>
            </ButtonMMM>
          </Col>
        </OverlayTrigger>
      )
    }
  } catch (e) {}

  return (
    <Col xs="auto" className="p-1">
      <ButtonMMM
        className="py-1"
        type="bg-switch"
        onClick={() => setImportingDataslayer(true)}
      >
        <span
          {...props}
          className={`inline-flex items-center align-middle ${props?.className ?? ''}`}
        >
          <FaPlus className="mr-2" /> {t('IMPORT DATA')}
        </span>
      </ButtonMMM>
    </Col>
  )
}

const AppTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [currentModel, setCurrentModel] = useState(null)
  const { token, refreshUsage, planUsage, isEssential, user } = useAuth()
  const [showFilters, setShowFilters] = useState(false)
  const [showHistory, setShowHistory] = useState(null)
  const [importingDataslayer, setImportingDataslayer] = useState(false)

  const handleShow = () => setShowFilters(true)
  const handleClose = () => setShowFilters(false)
  const { data, refetch, isLoading } = useQuery(
    ['models', token],
    async () => {
      const response = await getModels({ token })
      if (response?.ok) {
        const models = await response.json()
        models.sort((a, b) => {
          if (b.read_only ? 1 : 0) return 1
          if (a.read_only) return -1
          return new Date(b.updated_at) - new Date(a.updated_at)
        })
        return models.filter((m) => !(m.model_visible === false))
      }
      throw new Error('Error fetching models')
    },
    { staleTime: Infinity },
  )

  const [autoOpen, setAutoOpen] = useState(false)
  useEffect(() => {
    if (Array.isArray(data) && data.length === 0 && !autoOpen) {
      setImportingDataslayer(true)
      setAutoOpen(true)
    }
  }, [data, autoOpen])

  useEffect(() => {
    let interval
    if (Array.isArray(data)) {
      if (
        data
          .filter((m) => m.model_visible)
          .some(
            (model) =>
              getModelStatus(model) === 'importing' ||
              getModelStatus(model) === 'training',
          )
      ) {
        interval = setInterval(() => {
          refetch()
        }, 10000)
      }
    }
    return () => clearInterval(interval)
  }, [data, refetch])

  const _deleteModel = async ({ modelId }) => {
    await deleteModel({ modelId, token })
  }

  const columns = [
    {
      name: t('Name'),
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <Link
          className="no-underline text-white"
          to={`/model/${encodeURIComponent(row.id)}`}
          onClick={(e) => {
            if (getModelStatus(row) === 'importing') {
              e.preventDefault()
              e.stopPropagation()
              toast.warning(
                t('Model is still importing. Please wait until its ready.'),
              )
            }
          }}
        >
          {row.name}
        </Link>
      ),
    },
    {
      name: t('Rows'),
      selector: (row) => row.rows,
      sortable: true,
      width: '100px',
    },
    {
      name: t('Cols'),
      selector: (row) => row.cols,
      sortable: true,
      width: '100px',
    },
    {
      name: t('Created'),
      selector: (row) => row.created_at,
      cell: (row) => formatDateIndex(row.created_at),
      sortable: true,
      width: '200px',
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: {
            textAlign: 'center',
          },
        },
      ],
    },
    {
      name: t('Updated'),
      selector: (row) => row.updated_at,
      cell: (row) => formatDateIndex(row.updated_at),
      sortable: true,
      width: '200px',
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: {
            textAlign: 'center',
          },
        },
      ],
    },
    {
      name: t('Status'),
      selector: (row) => getModelStatus(row),
      sortable: true,
      width: '200px',
      cell: (row) =>
        getModelStatus(row) === 'importing' ? (
          <>
            {t(`importing`)}
            <FaSpinner className="spinner" />
          </>
        ) : (
          t(`${getModelStatus(row)}`)
        ),
    },
    {
      name: t('Accuracy'),
      selector: (row) => row.accuracy,
      sortable: true,
      cell: (row) => (
        <strong>
          {row?.accuracy &&
          (getModelStatus(row) === 'trained' ||
            getModelStatus(row) === 'optimizing')
            ? `${Number.parseInt(row?.accuracy < 0 ? 0 : row?.accuracy) ?? 0}%`
            : '-'}
        </strong>
      ),
      width: '120px',
    },
    {
      name: t('Actions'),
      cell: (row) => (
        <div className={`flex w-full justify-around`}>
          <Button
            as={Link}
            className={`button-mmm-actions bg-transparent ${getModelStatus(row) === 'importing' ? 'opacity-50 pointer-events-none' : ''}`}
            size="sm"
            to={`/model/${encodeURIComponent(row.id)}`}
          >
            <FaEye />
          </Button>{' '}
          <Button
            as={Link}
            className={`button-mmm-actions bg-transparent ${row?.read_only ? 'opacity-50 pointer-events-none' : ''}`}
            size="sm"
            onClick={() => row?.read_only || handleOpenModal(row)}
          >
            <FaEdit />
          </Button>{' '}
          <Button
            as={Link}
            className="button-mmm-actions bg-transparent"
            size="sm"
            onClick={() => setShowHistory(row)}
          >
            <FaTableList />
          </Button>{' '}
          <Button
            as={Link}
            className={`button-mmm-actions bg-transparent ${row?.read_only ? 'opacity-50 pointer-events-none' : ''}`}
            size="sm"
            onClick={() => row?.read_only || handleDelete(row)}
          >
            <FaTrashAlt />
          </Button>
        </div>
      ),
      width: '190px',
    },
  ]

  const paginationComponentOptions = {
    rowsPerPageText: `${t('Rows per page')}`,
    rangeSeparatorText: `${t('Of')}`,
    selectAllRowsItem: true,
    selectAllRowsItemText: `${t('All')}`,
  }

  const handleOpenModal = (model) => {
    model ? setEditMode(true) : setEditMode(false)
    setCurrentModel(model)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const mutation = useMutation(_deleteModel, {
    onSuccess: () => {
      queryClient.invalidateQueries('models')
      toast.dismiss() // Close the confirm toast
      toast.success('Record deleted successfully')
    },
    onError: (error) => {
      toast.dismiss() // Close the confirm toast
      toast.error(`Error deleting record: ${error.message}`)
    },
  })

  const handleDelete = (model) => {
    showConfirmToast(
      () => mutation.mutate({ modelId: model.id }),
      () => toast.dismiss(),
      `Are you sure you want to delete the model ${model.name}?`,
    )
  }

  const [filteredModels, setFilteredModels] = useState(data)

  useEffect(() => {
    setFilteredModels(data)
  }, [data])

  const handleApplyFilters = (filters) => {
    const { name, status } = filters
    let filtered = data
    if (name) {
      filtered = filtered.filter((model) =>
        model.name.toLowerCase().includes(name.toLowerCase()),
      )
    }
    if (status) {
      filtered = filtered.filter(
        (model) => model.status.toLowerCase() === status.value.toLowerCase(),
      )
    }
    setFilteredModels(filtered)
  }

  return (
    <Row>
      <Col xs={12}>
        <ContentNavbar />
      </Col>
      <Col xs={12} className="mb-2">
        <Row className="justify-between">
          <Col xs={'auto'}></Col>
          <Col xs={'auto offset-1'}>
            {!isLoading && (
              <StartImportButton
                setImportingDataslayer={setImportingDataslayer}
                className="text-xl"
              />
            )}
          </Col>
          <Col xs={'auto'}>
            <Row>
              <Col xs="auto" className="p-1">
                <ButtonMMM
                  className="button-mmm-info"
                  onClick={() => window.open(`${HELP_URL}`, '_blank')}
                >
                  <span className="inline-flex items-center align-middle">
                    <FaQuestionCircle className="me-2" /> {t('Help')}
                  </span>
                </ButtonMMM>
              </Col>
              <Col xs="auto" className="p-1">
                <ButtonMMM onClick={handleShow} className="button-mmm-info">
                  <span className="inline-flex items-center align-middle">
                    <FaFilter size={14} />
                  </span>
                </ButtonMMM>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <div className="data-table rounded-t-xl">
          <DataTable
            theme="mmm-table-theme"
            columns={columns}
            data={filteredModels || []}
            pagination
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      </Col>
      <Offcanvas
        className="off-canvas"
        show={showFilters}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t('Filters')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ModelFilter
            onApply={handleApplyFilters}
            onClean={() => setFilteredModels(data)}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <ModalUpload
        onEditModel={editMode}
        currentModel={currentModel}
        refetch={refetch}
        show={showModal}
        onHide={handleCloseModal}
      />
      <Modal
        show={importingDataslayer}
        onHide={() => setImportingDataslayer(false)}
        className="model-import-dataslayer"
        backdrop="static"
      >
        <Modal.Header closeButton className="pb-0"></Modal.Header>
        <Modal.Body>
          <ImportModel
            onFinish={(modelId, plan) => {
              setImportingDataslayer(false)
              refreshUsage()
              if (modelId) {
                navigate(
                  `/model/${encodeURIComponent(modelId)}?step=${plan === 'Essential' ? 1 : 0}`,
                )
              }
            }}
            showSplash={!data?.length}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={!!showHistory}
        onHide={() => setShowHistory(null)}
        size="xl"
        backdrop="static"
      >
        <Modal.Header closeButton className="pb-0">
          <Modal.Title>{t('Model history')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showHistory && <ModelHistory model={showHistory} />}
        </Modal.Body>
      </Modal>
    </Row>
  )
}

export default AppTable
