import React, { useMemo, useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { getMMMDynamicSpend, getMMMStatistics } from '../../services/model'
import {
  zip,
  integerParams,
  dateParams,
  getMMMDataColumnInfo,
  nivoLineProps,
  baseFormat,
} from '../../utility/model'
import Loader from '../Loader'
import { useTranslation } from 'react-i18next'
import { ResponsiveLine } from '@nivo/line'
import { animated } from '@react-spring/web'
import { useAnimatedPath } from '@nivo/core'
import YearlyLayer from './YearlyLayer'
import * as d3 from 'd3'
import { useAuth } from '../../providers/AuthProvider'
import { placeholderAdstock } from './placeholders'
import UpgradeToPro from './UpgradeToPro'

const CustomAreaLayer = ({ series, innerWidth, ...props }) => {
  const { t } = useTranslation()
  const areaGenerator = d3
    .area()
    .x((d) => d.position.x)
    .y0((d) => d.position.y)
    .y1((d) => props.yScale(0))
    .curve(d3.curveMonotoneX)

  const animatedPath = useAnimatedPath(
    areaGenerator(series.find((line) => line.id === t('spend'))?.data ?? []),
  )
  return (
    <animated.path
      key="custom-area"
      fillRule="even-odd"
      d={animatedPath}
      fill={'#ffc0cb'}
      fillOpacity={'0.6'}
    />
  )
}

function DiminishingReturns({ data, xScale, yScale, ...props }) {
  const spend = data.find((d) => d.id === 'spend')
  const cr = data.find((d) => d.id === 'Consumer response')
  let lagged = null
  let maxDiff = 0
  const diminishing = spend.data
    .map((d, i) => {
      if (i > 0) {
        const cy = d.y
        const py = spend.data[i - 1].y
        if (cy - py > maxDiff) {
          maxDiff = cy - py
          lagged = i - 1
        }
      }

      if (i > 4 && d.y > cr.data[i].y) return [d.x, d.y]
      return null
    })
    .filter((d) => d)

  if (lagged !== null) {
    if (lagged !== null) {
      const [sx1, sy1] = [spend.data[lagged].x, spend.data[lagged].y]
      const [sx2, sy2] = [spend.data[lagged + 1].x, spend.data[lagged + 1].y]
      const [cx1, cy1] = [cr.data[lagged].x, cr.data[lagged].y]
      const [cx2, cy2] = [cr.data[lagged + 1].x, cr.data[lagged + 1].y]
      const x1 = xScale((sx2 + sx1) / 2)
      const y1 = yScale((sy2 + sy1) / 2)
      const x2 = xScale((cx2 + cx1) / 2)
      const y2 = yScale((cy2 + cy1) / 2)
      const textY =
        Math.abs(y1 - y2) < 50 ? Math.min(y2, y1) - 30 : (y1 + y2) / 2
      lagged = (
        <g>
          <polyline
            points={`${x1} ${y1} ${x1 - 20} ${y1 - 10} ${x1 - 20} ${y1 + 10}`}
            fill={'#7777ff'}
            strokeWidth="1"
          />
          <polyline
            points={`${x1 - 20} ${y1} ${x1 - 50} ${y1}`}
            strokeWidth="2"
            stroke={'#7777ff'}
          />
          <polyline
            points={`${x2} ${y2} ${x2 + 20} ${y2 - 10} ${x2 + 20} ${y2 + 10}`}
            fill={'#7777ff'}
            strokeWidth="1"
          />
          <polyline
            points={`${x2 + 20} ${y2} ${x2 + 50} ${y2}`}
            strokeWidth="2"
            stroke={'#7777ff'}
          />
          <text
            y={textY}
            x={x1 - 120}
            fill={'#7777ff'}
            stroke={'black'}
            strokeWidth={0.5}
            fontWeight={700}
          >
            Lagged effect
          </text>
        </g>
      )
    }
  }

  return (
    <>
      {lagged}
      {diminishing.map(([x, y], i) => {
        x = xScale(x)
        y = yScale(y) - 4
        return (
          <g key={i}>
            {i ? (
              <></>
            ) : (
              <text
                x={x + 15}
                y={y - 3}
                fill="green"
                stroke="#008800"
                fontSize={14}
              >
                Diminishing returns
              </text>
            )}
            <polyline
              points={`${x} ${y} ${x - 10} ${y - 15} ${x + 10} ${y - 15}`}
              stroke="#4444ff44"
              fill={'green'}
              strokeWidth="1"
            />
          </g>
        )
      })}
    </>
  )
}

export default function DynamicSpend({
  model,
  isInView = true,
  showDiminishingReturns = false,
}) {
  const { t } = useTranslation()
  const { token, isEssential } = useAuth()
  const requirePro = isEssential && !model?.read_only

  const { data, isLoading, isSuccess } = useQuery(
    ['MMMDynamicSpend', model.id],
    async () => {
      if (requirePro) return placeholderAdstock
      const response = await getMMMDynamicSpend({ modelId: model.id, token })
      if (response?.ok) return await response.json()
    },
    { staleTime: Infinity },
  )

  const { data: statistics } = useQuery(
    ['mmm-model-statistics', model.id],
    async () => {
      const response = await getMMMStatistics({
        modelId: model.id,
        token,
      })

      if (!response?.ok) toast.error(t('Failed to retrieve original forecast'))
      const res = await response.json()
      return res
    },
    { staleTime: 60 * 1000 },
  )

  if (!window.item) window.item = data

  const Diminishing = useMemo(
    () => (showDiminishingReturns ? DiminishingReturns : () => {}),
    [showDiminishingReturns],
  )

  const dateInfo = useMemo(
    () =>
      statistics?.media_data
        ? getMMMDataColumnInfo(model, '', {
            customDates: statistics?.media_data?.map((d) => new Date(`${d}Z`)),
          })
        : getMMMDataColumnInfo(model),
    [model, statistics],
  )

  const [lines, params, maxY, customLayer] = useMemo(() => {
    const params = dateInfo.mode === 'datetime' ? dateParams : integerParams
    if (data && !isLoading && statistics) {
      console.log(data, statistics)
      try {
        const { spend, adstock } = data
        let start = 0,
          end = 0
        if (Array.isArray(spend.x)) {
          start = spend.x[0]
          end = spend?.x?.[spend?.x?.length - 1]
        }
        return [
          [
            {
              // TODO: changing the id is ugly but label isn't working, why is label not working?
              id: 'adstock',
              label: 'adstock',
              data: zip([adstock.x, adstock.y]).map(([x, y]) => ({
                x: dateInfo.map(x),
                y,
              })),
              color: '#dc143c',
              areaColor: '#00000000',
            },
            {
              id: 'spend',
              label: t('spend'),
              data: zip([spend.x, spend.y]).map(([x, y]) => ({
                x: dateInfo.map(x),
                y,
              })),
              color: '#ffc0cb',
            },
          ],
          params,
          Math.max(...spend.y, ...adstock.y),
          (props) => (
            <YearlyLayer
              start={start}
              ignoreLessThanYear={true}
              end={end}
              extraWidth={0}
              {...props}
            />
          ),
        ]
      } catch (e) {}
    }
    return [[], params, 'auto', () => <></>]
    // eslint-disable-next-line
  }, [data, statistics, isLoading, model])

  const csvData = lines?.[0]?.data
    ? [
        [t('week'), t('spend'), 'adstock'],
        ...lines[0].data.map(({ x, y }, i) => [x, lines[1].data[i].y, y]),
      ]
    : []

  const [shownData, setShownData] = useState(null)

  useEffect(() => {
    if (isSuccess) {
      setShownData(
        lines?.map((d) => ({
          ...d,
          id: t(d.id),
          label: t(d.label),
          data: d.data.map(({ x, y }) => ({ x, y: 0 })),
        })),
      )
      if (isInView)
        setTimeout(
          () =>
            setShownData(
              lines.map((d) => ({ ...d, id: t(d.id), label: t(d.label) })),
            ),
          100,
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isInView, data, t])

  return isLoading || !shownData ? (
    <Loader />
  ) : (
    <Row className={`relative ${requirePro ? 'blur-under' : ''}`}>
      {requirePro && <UpgradeToPro />}
      <Col xs={12}>
        <ResponsiveLine
          {...nivoLineProps}
          {...params}
          data={shownData}
          margin={{
            top: showDiminishingReturns ? 100 : 50,
            right: 50,
            bottom: 90,
            left: 100,
          }}
          yScale={{
            type: 'linear',
            min: 0,
            max: maxY,
            stacked: false,
            reverse: false,
          }}
          enableArea={false}
          colors={(d) => d.color}
          curve={'linear'}
          yFormat=" >-.2f"
          enablePoints={false}
          enableGridX={false}
          enableGridY={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            ...params.axisBottom,
            legend: t('Week'),
            legendOffset: 80,
          }}
          axisLeft={{
            ...nivoLineProps.axisLeft,
            legendOffset: -90,
            legend: t('Spend'),
          }}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          useMesh={true}
          enableSlices="x"
          sliceTooltip={({ slice }) => {
            return (
              <Row className="tooltip-adstock pt-2 px-2">
                {slice.points.map((point, i) => (
                  <Col key={i} xs={12} className="pb-2">
                    <Row>
                      <Col
                        className="d-inline-flex align-items-center text-black"
                        xs={6}
                      >
                        <div
                          className="d-inline-block me-1 mt-1"
                          style={{
                            minWidth: '12px',
                            minHeight: '12px',
                            backgroundColor: point.serieColor,
                          }}
                        ></div>
                        <span className="text-black">{point.serieId}</span>
                      </Col>
                      <Col xs={6} className="text-end text-black">
                        <strong>{point.data?.yFormatted}</strong>
                        <i className="ms-2 smallp">
                          {dateInfo.mode === 'datetime'
                            ? `${baseFormat(point?.data.x)}`
                            : `${t('Week')} ${point?.data?.x}`}
                        </i>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            )
          }}
          legends={[
            {
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -35,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'square',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
            },
          ]}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            'legends',
            CustomAreaLayer,
            'lines',
            'points',
            'slices',
            () => (
              <div
                className="data-holder display-none"
                data-csv={encodeURIComponent(JSON.stringify(csvData))}
                data-filename={`dynamic_spend__${model.id}`}
              ></div>
            ),
            customLayer,
            Diminishing,
          ]}
        />
      </Col>
    </Row>
  )
}
