import { useTranslation } from 'react-i18next'
import { config } from '../Constants'

import ButtonMMM from './ButtonMMM'
import { useAuth } from '../providers/AuthProvider'
import { FaFilePdf } from 'react-icons/fa6'
import { useQuery } from 'react-query'
import { getGraphDetails } from '../services/model'

export default function ExportReport({ model }) {
  const { token } = useAuth()
  const { t } = useTranslation()
  const { data: graphDetails, isLoading: isLoadingsDetails } = useQuery(
    ['graphDetails', model?.id],
    async () => {
      const response = await getGraphDetails({ modelId: model?.id, token })
      if (!response?.ok) throw new Error(response?.statusText)
      const graphDetails = await response.json()
      return graphDetails
    },
    { staleTime: Infinity },
  )

  return (
    <ButtonMMM
      disabled={!graphDetails || !model}
      onClick={() => {
        const link = document.createElement('a')
        link.href = `${config.API_URL}/download-pdf-report/${model?.id}?token=${token}`
        link.setAttribute('download', `report_${model.id}.pdf`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }}
      className={`model-dropdown ${!graphDetails || !model ? 'pointer-events-none opacity-50' : ''}`}
    >
      <span className="inline-flex items-center">
        <FaFilePdf className="me-2 " /> {t('Download report')}
      </span>
    </ButtonMMM>
  )
}
